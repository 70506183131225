import React, {FC} from "react";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import Prices from "../Prices";
import AsyncAction from "../AsyncAction";
import WishIcon from "../WishIcon";
import FailSvg from "-!svg-react-loader!../../../svg/fail.svg";
import CheckToastSvg from "-!svg-react-loader!../../../svg/check.svg";
import {useToast} from "../../../atoms/Toast/useToast";
import {useDispatch, useSelector} from "react-redux";
import {IGlobalProduct, IState} from "../../../types/state";
import {wishlistAddItem, wishlistRemoveItem} from "../../../store/wishlist"

const HotelAuthList: FC<{ product: IGlobalProduct }> = ({
                                                       product,
                                                   }) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const wishlist = useSelector((state: IState) => state.wishlist);
    const selectedData = useSelector((state: IState) => state.locale.code);
    const signed = useSelector((state: IState) => state.customer.authenticated);
    const wishlistCheckArray = wishlist.find((x: any) => {
        return x.id == product.id;
    });
    const {product_flat} = product ?? {};
    const {name} = product_flat?.[0] ?? {};

    let addAndRemoveWishList = () => {
        const removeProduct = "was successfully removed from the whishlist"
        toast.open(
            <span className="d-flex chek-fms">
            {wishlistCheckArray == undefined ? <CheckToastSvg/> : <FailSvg/>}
                <FormattedMessage
                    id={wishlistCheckArray == undefined ? "added_to_wishlist_toast" : "producthasalreadyinwishlist"}
                    defaultMessage={`Product "${name}" ${wishlistCheckArray == undefined ? "added to wish list" : removeProduct}`}
                />
            </span>
        );
    };
    return (
        <div className="product-card__availability-mobile">
            {/*<div className="product-card__availability">*/}
            {/*    <FormattedMessage*/}
            {/*        id="availability"*/}
            {/*        defaultMessage="Availability"*/}
            {/*    />{" "}*/}
            {/*    :*/}
            {/*    <span className="text-success">*/}
            {/*        <FormattedMessage id="in_stock" defaultMessage="In stock"/>*/}
            {/*    </span>*/}
            {/*</div>*/}
            <div className="prices" style={{display: "flex"}}>
                <Prices product={product} cart={true}/>
            </div>
            {/*<div className="product-card__buttons">*/}
            {/*    {*/}
            {/*        signed ? (*/}
            {/*            <span onClick={addAndRemoveWishList}>*/}
            {/*                <AsyncAction*/}
            {/*                    // @ts-ignore*/}
            {/*                    action={() =>*/}
            {/*                        dispatch(*/}
            {/*                            wishlistCheckArray*/}
            {/*                                ? wishlistRemoveItem(product.id)*/}
            {/*                                : wishlistAddItem(product, selectedData)*/}
            {/*                        )*/}
            {/*                    }*/}
            {/*                    render={({run, loading}: { run: () => void; loading: boolean }) => (*/}
            {/*                        <div*/}
            {/*                            onClick={run}*/}
            {/*                            className={classNames(" btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist", {*/}
            {/*                                "btn-loading": loading,*/}
            {/*                            })}*/}
            {/*                        >*/}
            {/*                            {" "}*/}
            {/*                            <WishIcon product={product}/>*/}
            {/*                        </div>*/}
            {/*                    )*/}
            {/*                    }*/}
            {/*                />*/}
            {/*            </span>*/}
            {/*        ) : (*/}
            {/*            <div*/}
            {/*                onClick={(e) => {*/}
            {/*                    e.preventDefault();*/}
            {/*                    toast.open(*/}
            {/*                        <span className="d-flex faild-toast-fms">*/}
            {/*                            <FailSvg/>*/}
            {/*                            <FormattedMessage*/}
            {/*                                id="please_signin_toast"*/}
            {/*                                defaultMessage="Please sign in or register"*/}
            {/*                            />*/}
            {/*                        </span>*/}
            {/*                    );*/}
            {/*                }}*/}
            {/*                className="btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"*/}
            {/*            >*/}
            {/*                <WishIcon product={product}/>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    )
}

export default HotelAuthList;
