import React from "react";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {IProduct} from "../../types/homePageTypes";

const Currency = dynamic(() => import('./Currency'))

const START_DATE = "1970-01-01";

interface IPriceProps {
    product: any,
    cart?: boolean,
    total?: boolean,
    price?: boolean,
}

export const CheckoutPrice = (product: IProduct, rate: number) => {
    let newDate = Math.round(new Date().getTime() / 1000);
    // @ts-ignore
    let date_from = product?.product?.product_flat[0]?.special_price_from
    // @ts-ignore
    let date_to = product?.product?.product_flat[0]?.special_price_to
    const date_now = newDate

    // @ts-ignore
    if (
        // @ts-ignore
        product?.product?.product_flat[0]?.special_price
        && date_now >= date_from
        && date_now <= date_to
    ) { // @ts-ignore
        return <Currency value={Number(product?.product?.product_flat[0]?.special_price * rate).toFixed(2)}/>
        // @ts-ignore
    } else if (product?.product?.product_flat[0]?.special_price
        && date_from === null
        && date_to === null) {
        // @ts-ignore
        return <Currency value={Number(product?.product?.product_flat[0]?.special_price * rate).toFixed(2)}/>
    } else {
        // @ts-ignore
        return <Currency value={Number(product?.product?.product_flat[0]?.price * rate).toFixed(2)}/>
    }
}

const Prices = ({product, cart = false, total = false, price = false}: IPriceProps) => {

    const rate: number = useSelector((state: IState) => state.rate.exchange_rate.rate) || 1;
    const clsName = cart ? "product-card__prices" : "product__prices color-lightgray"
    // FIXME product?.price????
    // @ts-ignore
    if (product?.price) {
        // @ts-ignore
        // return <Currency value={Number(product?.price * rate).toFixed(2)}/>
        if (!product.special_price) {
            return <Currency value={Number(product?.price * rate).toFixed(2)}/>
        } else {
            return <>
                <span className="product-card__new-price">
                    <Currency value={Number(product?.special_price * rate).toFixed(2)}/>
                </span>
                <span className="product-card__old-price">
                    <Currency value={Number(product?.price * rate).toFixed(2)}/>
                </span>
            </>
        }

    } else {
        let newDate = Math.round(new Date().getTime() / 1000);
        let date_from = product?.product_flat[0]?.special_price_from
        let date_to = product?.product_flat[0]?.special_price_to
        let date_now = newDate

        const OldNewPrices = () => {
            // @ts-ignore
            return (
                <>
                    <span className="product-card__new-price">
                        <Currency value={Number(product?.product_flat[0]?.special_price * rate).toFixed(2)}/>
                    </span>
                    <span className="product-card__old-price">
                        <Currency value={Number(product?.product_flat[0]?.price * rate).toFixed(2)}/>
                    </span>
                </>
            )
        }

        const checkPrice = () => {
            if (
                product?.product_flat[0]?.special_price
                && date_now >= date_from
                && date_now <= date_to
            ) {
                return <OldNewPrices/>
            } else if (product?.product_flat[0]?.special_price
                && date_from === null
                && date_to === null) {
                return <OldNewPrices/>
            } else if (product?.type === "configurable") {
                return <Currency value={Number(product?.product_flat[0]?.min_price * rate).toFixed(2)}/>

            } else {
                return <Currency value={Number(product?.product_flat[0]?.price * rate).toFixed(2)}/>
            }
        }

        /***************** Total price calculate *******************/

        const TotalPrice = () => {
            const onlyNumbers = product?.replace(/[^0-9.]/g, '');
            return <Currency value={Number(onlyNumbers * rate).toFixed(2)}/>
        }

    }

    let newDate = Math.round(new Date().getTime() / 1000);
    let date_from = product?.product_flat[0]?.special_price_from
    let date_to = product?.product_flat[0]?.special_price_to
    let date_now = newDate


    const OldNewPrices = () => {
        return (
            <>
                <span className="product-card__new-price">
                    <Currency value={Number(product?.product_flat[0]?.special_price * rate).toFixed(2)}/>
                </span>
                <span className="product-card__old-price">
                    <Currency value={Number(product?.product_flat[0]?.price * rate).toFixed(2)}/>
                </span>
            </>
        )
    }

    const checkPrice = () => {
        if (
            product?.product_flat[0]?.special_price
            && date_now >= date_from
            && date_now <= date_to
        ) {
            return <OldNewPrices/>
        } else if (product?.product_flat[0]?.special_price
            && date_from === null
            && date_to === null) {
            return <OldNewPrices/>
        } else if (product?.type === "configurable") {
            return <Currency value={Number(product?.product_flat[0]?.min_price * rate).toFixed(2)}/>

        } else {
            return <Currency value={Number(product?.product_flat[0]?.price * rate).toFixed(2)}/>
        }
    }

    /***************** Total price calculate ********************/

    const TotalPrice = () => {
        const onlyNumbers = product?.replace(/[^0-9.]/g, '');
        return <Currency value={Number(onlyNumbers * rate).toFixed(2)}/>
    }


    return (
        total
            ? <TotalPrice/>
            : <div className={clsName}>
                {price ?
                    <Currency value={Number(product?.price * rate).toFixed(2)}/>
                    : checkPrice()
                }
            </div>
    )
}

export default Prices;
