import React, {FC} from "react";
import Link from "next/link";
import {rightUrl, url} from "../../../services/utils";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {useRouter} from "next/router";
import {IAddToCart as IProps} from "./types";

const AddToCart: FC<IProps> = ({
                                   product,
                                   prefetch,
                                   tablet,
                                   route,
                               }) => {
    const router = useRouter();
    return (
        <Link
            // href={tablet ? rightUrl(url.product(product), route ? router : null) : url.product(product)}
            href={{
                // @ts-ignore
                pathname: `${url.product(product)}`,
                query: {...router.query},
            }}
            prefetch={prefetch}
        >
            <a>
                <button
                    type="button"
                    className={
                        classNames(
                            `btn btn-primary ${tablet
                                ? "product-card__addToCart-tablet show-for-tablet btn-primary-fms"
                                : "product-card__addToCart hide-for-tablet"
                            }`,
                        )
                    }
                >
                    <FormattedMessage
                        id="add_to_cart"
                        defaultMessage="Add to cart"
                    />
                </button>
            </a>

        </Link>
    )
}

export default AddToCart;
