import React, {FC} from "react"
import {useSelector} from "react-redux"
import {IGlobalProduct, IState} from "../../types/state";
import classNames from "classnames";
// SVG
import {HotelHeart} from "../../svg";


interface IWIshIconProps {
    product: any | React.ReactElement | React.ReactNode
}

const WishIcon: FC<IWIshIconProps> = ({product}) => {


    const wishlist = useSelector((state: IState) => state.wishlist)

    const isProductInWishList = wishlist?.find((item: IGlobalProduct) => item.id === product.id)

    const classes = classNames('wishlist-circle', {
        'wishlist-circle-clicked': isProductInWishList
    })


    return (
        <div className="wishlist-icon-wrapper">
            {/*<WishCircle className={classes}/>*/}
            {
                isProductInWishList
                    ? <HotelHeart className="inner-wishlist"/>
                    : <HotelHeart/>
            }
        </div>
    )
}

export default WishIcon