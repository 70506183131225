import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import Link from "next/link";
import {useRouter} from "next/router";
import dynamic from 'next/dynamic'
import {setCrossValid, setPopupName, setWindowSize} from "../../store/general";
import {cartAddItem, getUpOrCrossProd} from "../../store/cart";
// SVG
import {CheckToastSvg, FailSvg} from "../../svg";

import {rightUrl, url} from "../../services/utils";
import defaultPicture from '../../images/defaultpic.webp'
import {useToast} from "../../atoms/Toast/useToast";
import {IGlobalProduct, IState} from "../../types/state";
// @ts-ignore
const AsyncAction = dynamic(() => import('./AsyncAction'))
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {IProductCart as IProps} from "./ProductCard/types";
import ProductCardImage from "./ProductCard/ProductCardImage";
import CrossButton from "./ProductCard/CrossButton";
import AddToCart from "./ProductCard/AddToCart";
import {containerClasses} from "./ProductCard/helpers";
import HotelAuthList from "./ProductCard/Hotel_AuthList";
import HotelProductCardInfo from "./ProductCard/Hotel_ProductCardInfo";
import {wishlistAddItem, wishlistRemoveItem} from "../../store/wishlist"
import classNames from "classnames";
import WishIcon from "./WishIcon";


const HotelProductCard: FC<IProps> = (props: IProps) => {
    const {
        customer = false,
        product,
        layout,
    } = props

    const locale = useSelector((state: IState) => state.locale.code);
    const rate = useSelector((state: IState) => state.rate.exchange_rate);
    const currency = useSelector((state: IState) => state.rate.current.code);
    const selectedData = useSelector((state: IState) => state.locale.code);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const backorders = useSelector((state: IState) => state.general.Backorders);
    const wishlist = useSelector((state: IState) => state.wishlist);
    const signed = useSelector((state: IState) => state.customer.authenticated);
    // const [dimension, setDimension] = useState<number>(1200);
    const router = useRouter();
    const toast = useToast();
    const dispatch = useDispatch();
    const {product_flat} = product ?? {};
    const {
        name,
        has_up_sell,
        has_cross_sell,
    } = product_flat?.[0] ?? {};


    const isFullArray = product?.product_flat?.length > 0
    const addCart = isFullArray && has_up_sell == 0 && has_cross_sell == 0
    const onlyCross = isFullArray && has_up_sell == 0 && has_cross_sell == 1;
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    let diffProductImage = product
    && product.product_images.length > 0
        ? `/storage/medium/${product?.product_images[0].path}`
        : defaultPicture.src

    const wishlistCheckArray = wishlist.find((x: any) => {
        return x.id == product.id;
    });

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 768)
        setIsTablet(windowSize < 1024)
    }, [windowSize])

    // FIXME if need , should i add types for  unmaunted useEffect
    const addToast = () => {
        toast.open(
            <span className="d-flex chek-fms">
                <CheckToastSvg/>
                <FormattedMessage
                    id="added_to_cart_toast"
                    defaultMessage={`Product "${name}" added to cart`}
                />
            </span>
        );
    }

    const openUpCrossProd = (product: IGlobalProduct) => {
        let hasUpSell = has_up_sell == 1;
        let hasCrossSell = has_cross_sell == 1;
        let page = true;

        if (!hasUpSell && hasCrossSell) {
            dispatch(getUpOrCrossProd(product.id, 'crossSell', locale, currency, rate.rate, page))
            dispatch(setCrossValid(true));
            dispatch(setPopupName("crossSell"));
        }
        if (hasUpSell) {
            dispatch(setPopupName("upsell"));
            dispatch(getUpOrCrossProd(product.id, 'upSell', locale, currency, rate.rate, page))
        }
    };

    const isProductInStock = (product: { product_inventories: { qty: number }[]; }) => {
        let qty = product?.product_inventories[0].qty
        return (qty > 0 || qty === 0 && backorders)
    }

    const asyncAction = (
        toastAdd?: boolean,
        temp?: boolean,
        crossProd?: boolean,
        dispatching?: boolean,
        tablet?: boolean,
        load?: boolean
    ) => {
        const action = () => {
            if (!dispatching) {
                return dispatch(cartAddItem(product, [], 1, cartToken, customer, selectedData, null, 'homePage'));
            }
        };
        return (
            <AsyncAction
                action={action as () => ((dispatch: any) => Promise<any> | undefined) | undefined}
                render={({run: run, loading: loading}) => (
                    <CrossButton
                        run={run}
                        loading={loading}
                        product={product}
                        openUpCrossProd={openUpCrossProd}
                        isProductInStock={isProductInStock}
                        toastAdd={toastAdd}
                        temp={temp}
                        crossProd={crossProd}
                        tablet={tablet}
                        addToast={addToast}
                        load={load}
                    />)}
            />
        )
    }
    const handlerProductType = (tablet?: boolean) => {
        let actionParams: boolean[];
        if (product.product_flat[0]?.min_qty && product.product_flat[0]?.min_qty !== "1") {
            return (
                <AddToCart
                    prefetch={false}
                    product={product}
                    tablet={!!tablet}
                    route={true}
                />
            );
        }

        switch (product?.type) {
            case 'configurable':
            case 'bundle':
                return (
                    <AddToCart
                        prefetch={false}
                        product={product}
                        tablet={!!tablet}
                        route={true}
                    />
                );
            default:
                if (addCart) {
                    actionParams = tablet ? [true, false, false, false, true] : [true];
                } else if (onlyCross) {
                    actionParams = tablet ? [true, true, true, false, true] : [false, true, true, false, false];
                } else {
                    actionParams = tablet ? [true, true, true, false, true] : [false, true, true, true, false, true];
                }
                break;
        }

        return asyncAction(...actionParams);
    };

    if (!product) {
        return null;
    }

    let addAndRemoveWishList = () => {
        const removeProduct = "was successfully removed from the whishlist"
        toast.open(
            <span className="d-flex chek-fms">
            {wishlistCheckArray == undefined ? <CheckToastSvg/> : <FailSvg/>}
                <FormattedMessage
                    id={wishlistCheckArray == undefined ? "added_to_wishlist_toast" : "producthasalreadyinwishlist"}
                    defaultMessage={`Product "${name}" ${wishlistCheckArray == undefined ? "added to wish list" : removeProduct}`}
                />
            </span>
        );
    };

    const wishListButton = (
        <div className="product-card__buttons">
            {
                signed ? (
                    <span onClick={addAndRemoveWishList}>
                    <AsyncAction
                        // @ts-ignore
                        action={() =>
                            dispatch(
                                wishlistCheckArray
                                    ? wishlistRemoveItem(product.id)
                                    : wishlistAddItem(product, selectedData)
                            )
                        }
                        render={({run, loading}: { run: () => void; loading: boolean }) => (
                            <div
                                onClick={(e) => {
                                    e.preventDefault()
                                    run()
                                }}
                                className={classNames(" btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist", {
                                    "btn-loading": loading,
                                })}
                            >
                                {" "}
                                <WishIcon product={product}/>
                            </div>
                        )
                        }
                    />
                </span>
                ) : (
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toast.open(
                                <span className="d-flex faild-toast-fms">
                                <FailSvg/>
                                <FormattedMessage
                                    id="please_signin_toast"
                                    defaultMessage="Please sign in or register"
                                />
                            </span>
                            );
                        }}
                        className="btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist"
                    >
                        <WishIcon product={product}/>
                    </div>
                )
            }
        </div>
    )

    const productImage = product.product_images ? (
        <div className="product-card__image product-image">
            <Link
                // href={rightUrl(url.product(product), router)}
                href={{
                    // @ts-ignore
                    pathname: `${url.product(product)}`,
                    query: {...router.query},
                }}
                prefetch={false}
                className={`${isTablet ? "product-image__body" : ""}`}
            >
                <a>
                    <div className="product-image__body product-image__body-fms">
                        {/*<div className="item_overlay hide-for-tablet"></div>*/}
                        {/*<div className="img_btn_wrapper">{handlerProductType()}</div>*/}
                        <LazyLoadImage
                            alt={name}
                            width={isMobile ? 120 : !isTablet ? 280 : undefined}
                            height={isMobile ? 120 : !isTablet ? 280 : undefined}
                            src={`${diffProductImage}`}
                        />
                        <HotelProductCardInfo product={product}/>
                        <div className="product-card__actions">
                            <HotelAuthList product={product}/>
                        </div>
                        {wishListButton}
                    </div>
                </a>
            </Link>
            {handlerProductType(true)}
        </div>
    ) : (
        <ProductCardImage
            product={product}
            router={router}
            openUpCrossProd={openUpCrossProd}
            addToast={addToast}
        />
    );

    return (
        <>
            <div className={containerClasses(layout)}>
                {productImage}
                {/*<ProductCardInfo product={product} />*/}

            </div>
        </>
    );
}


export default HotelProductCard;
