import React, {FC} from "react";
import Link from "next/link";
import {rightUrl, url} from "../../../services/utils";
import {setCrossValid, setTempData} from "../../../store/general";
import {useDispatch} from "react-redux";
import {IProductCardImage as IProps} from "./types";

const ProductCardImage: FC<IProps> = ({
    product,
    router,
    openUpCrossProd,
    addToast
}) => {
    const dispatch = useDispatch();
    return (
        <Link
            href={rightUrl(url.product(product), router)} prefetch={false}
            className="product-image__body"
        >
            <a>
                <div className="product-image__body product-image__default-fms ">
                    <div className="item_overlay hide-for-tablet"></div>
                    <div className="img_btn_wrapper">
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(setTempData([product]))
                                dispatch(setCrossValid(false))
                                openUpCrossProd(product)
                                addToast(product)
                            }}
                            type="button"
                            className="btn btn-primary product-card__addToCart hide-for-tablet"
                        >
                            Add to cart
                        </button>
                    </div>
                </div>
            </a>
        </Link>
    )
}

export default ProductCardImage;
